











































import {Component, Vue} from 'vue-property-decorator';
import {readUserProfile} from '@/store/main/getters';
import {api} from "@/api";

@Component
export default class Dashboard extends Vue {
  get greetedUser() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      if (userProfile.full_name) {
        return userProfile.full_name;
      } else {
        return userProfile.email;
      }
    }
  }

  public downloadTemplate(item) {
    item.isDownloading = true;
    const notification = {
      content: 'Getting ' + item.filename + ' ...',
      showProgress: true,
    }
    // commitAddNotification(this.$store, notification);
    api.fileDownloadTemplate(item.id, this.$store.getters.token).then((response) => {
      let blob = new Blob([response.data],
          {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = item.filename
      link.click()
      // commitRemoveNotification(this.$store, notification);
      item.isDownloading = false;
    }).catch(error => {
      item.isDownloading = false;
      // commitRemoveNotification(this.$store, notification);
      // commitAddNotification(this.$store, {content: 'Can not read metadata, reason: ' + error.message, color: "error"})
    });
  }

  get items() {
    return [
      {
        icon: 'assignment',
        iconClass: 'blue white--text',
        title: 'Generic template',
        filename: 'GenericTemplate.xlsx',
        id: 'GenericTemplate_v2.xlsx',
        isDownloading: false
      },
      {
        icon: 'assignment',
        iconClass: 'blue white--text',
        title: 'Campain template',
        filename: 'CampainTemplate.xlsx',
        id: 'CampainTemplate.xlsx',
        isDownloading: false
      },
            {
        icon: 'assignment',
        iconClass: 'blue white--text',
        title: 'Arc furnace (170221)', 
        filename: 'Arc_furnace_template_170221.xlsx',
        id: 'Arc_furnace_template_170221.xlsx',
        isDownloading: false
      },
            {
        icon: 'assignment',
        iconClass: 'blue white--text',
        title: 'Induction furnace (170221)',
        filename: 'Induction_furnace_Template_170221.xlsx',
        id: 'Induction_furnace_Template_170221.xlsx',
        isDownloading: false
      },
    ]
  }
}

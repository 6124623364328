









































































import { Component, Vue } from "vue-property-decorator";
import { readUserProfile } from "@/store/main/getters";

@Component
export default class Dashboard extends Vue {
  items = [
    {
      question: "Where do I find the templates?",
      answear:
        "Check the 'Templates' point on the left, download by pressing on the arrow next to the template name.",
    },
    {
      question: "How do I upload data?",
      answear: "Select the menu point 'Upload data' on the left.",
    },
    {
      question: "Which file name should I use for the upload?",
      answear:
        "This is up to you, the Data Hub stores the data using unique identifiers and does not depend any specific filename. But keep the ending (xlsx) of the template.",
    },
    {
      question: "When I upload a file, I do not see any metadata to validate?",
      answear:
        "In this case, the parsing of the metadata failed. Make sure that your metadata is in column C of the template.",
    },
    {
      question: "How do I search for data?",
      answear:
        "Use 'Search and download data' on the left. Press 'Append' after each search term you enter. Once you have refined your search, press 'search'. You can also display all uploaded data by hitting 'search' without any parameters. The search results can be ordered by pressing the column name (e.g. Uploader, Timestamp, etc).",
    },
    {
      question: "Where is the data stored?",
      answear:
        "Physically, the data hub is located on servers at NTNU in Trondheim (Norway).",
    },
    {
      question: "How are data secured?",
      answear:
        "Both data for users and uploaded files are stored in separate databases behind NTNU firefall on dedicated server.",
    },
    {
      question: "How often is the data backed-up?",
      answear:
        "We host the data on a self-healing OpenStack cluster with 3 nodes continuously mirroring the data to protect against any hardware failure. In addition, snapshots of the data are stored every 24 hours on a physically separated server.",
    },
    {
      question: "Who has access to the Data Hub?",
      answear:
        "Only project members of the SisAl Data Hub can access the data on the hub.",
    },
    {
      question: "Can I share the data I found on the Data Hub?",
      answear:
        "Generally no. All data stored on the Data Hub is confidential. Any outside sharing must be discussed with the owner of the data and the project consortium.",
    },
    {
      question: "How is my password stored?",
      answear:
        "We do not save the password in plain text instead we use hash of your password, so even in case of compromised users database your password will not be exposed.",
    },
    {
      question: "I have another question",
      answear: "Drop us an email by choosing 'Report Issue' on the left.",
    },
  ];

  get greetedUser() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      if (userProfile.full_name) {
        return userProfile.full_name;
      } else {
        return userProfile.email;
      }
    }
  }
}

















































































































































































































































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {api} from "@/api";
import {commitAddNotification, commitRemoveNotification} from '@/store/main/mutations';

@Component
export default class Search extends Vue {

  @Prop({default: []}) public items;
  @Prop({default: {'sortBy': 'score', 'descending': true, 'rowsPerPage': 10}}) public pagination;
  @Prop({
    default: [
      // "all : all",
      // "all : Uploader",
      // "all : Uploaded Date",
      // "required : Title",
      //  "required : Version",
      // "required : Partner", 
      // "required : Contact",
      // "required : Location", 
      // "required : WP/Task",
      // "required : Type", 
      // "required : Date", 
      // "required : Ownership",
      // "required : Description",
    ]
  }) public metadataKeys;
  @Prop({default: {}}) public metadataValues;
  @Prop({default: []}) public searchList;
  @Prop({default: ''}) public searchValue;
  @Prop({default: false}) public loadingMetadataKey;
  @Prop({default: false}) public loadingMetadataValues;
  @Prop({default: "all : all"}) public selectedKey;
  @Prop({default: ""}) public selectedValue;
  @Prop({default: false}) public loadingSearchResults;
  @Prop({default: false}) public isFileDownload;
  @Prop({default: false}) public menuDateFrom;
  @Prop({default: new Date().toISOString().substr(0, 10)}) public dateFrom;
  @Prop({default: false}) public menuDateTo;
  @Prop({default: new Date().toISOString().substr(0, 10)}) public dateTo;

  Partners = [
    'Befesa',
    'BNW',
    'Dow',
    'Elkem',
    'ERIMSA',
    'FRey',
    'Hydro',
    'HZDR',
    'ITMATI',
    'Mintek',
    'MNTK',
    'MYTIL',
    'NTNU',
    'NTUA',
    'RWTH',
    'SBC',
    'Silicor',
    'SIMTEC',
    'SINTEF',
    'SiQA1',
    'SiQAl',
    'Wacker',
    'WS'
 ]


  get headers() {
    return [
      {
        text: 'Title',
        align: 'left',
        sortable: true,
        value: 'title'
      },
      {text: 'Version', align: 'left', sortable: true, value: 'version'},
      {text: 'Ownership', align: 'left', sortable: true, value: 'ownership'},
      {text: 'Uploader', align: 'left', sortable: true, value: 'uploader'},
      {text: 'Timestamp', align: 'left', sortable: true, value: 'formattedTimestamp'},
    ]
  }

  get isAdvancedSearchListEmpty() {
    return this.searchList.length == 0;
  }

  get isAdvancedSearchEmpty() {
    return this.searchValue == '';
  }

  public timeConverter(UNIX_timestamp) {
    function pad(n){return n<10 ? '0'+n : n}

    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = pad(a.getHours());
    var min = pad(a.getMinutes());
    var sec = pad(a.getSeconds());
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
  }

  public searchAdvanced() {
    this.items = [];
    this.loadingSearchResults = true;
    let query = new URLSearchParams();
    this.searchList.forEach(item => {
      query.append('query', item.key + ' : ' + item.value)
    })
    const notification = {
      content: 'Getting metadata...',
      showProgress: true,
    }
    commitAddNotification(this.$store, notification);
    api.searchMetadataFields(this.$store.getters.token, query).then((ret) => {
      this.items = []
      ret.data.forEach(item => {
        let version = '';
        let ownership = '';
        let title = '';
        item.metadata.metadata.required.forEach(required => {
          if (required.key == 'Ownership') {
            ownership = required.value;
          }
          if (required.key == 'Title') {
            title = required.value;
          }
          if (required.key == 'Version') {
            version = required.value;
          }
        })
        this.items.push({
          _id: item._id,
          title: title,
          version: version,
          filename: item.metadata.filename,
          ownership: ownership,
          uploader: item.metadata.owner,
          formattedTimestamp: this.timeConverter(item.metadata.timestamp),
          metadata: item.metadata.metadata,
          isDownloading: false,
        })
      })
      this.loadingSearchResults = false;
      commitRemoveNotification(this.$store, notification);
    }).catch(error => {
      this.loadingSearchResults = false;
      commitRemoveNotification(this.$store, notification);
      commitAddNotification(this.$store, {content: 'Can not read metadata, reason: ' + error.message, color: "error"})
    });
  }

  public refreshMetadataKey() {
    this.loadingMetadataKey = true;
    api.templatesMetadataKeys(this.$store.getters.token).then((response) => {
      let metadataKeys : string[] = [];
      response.data.forEach(item => {
        const name = item.name;
        item.keys.forEach(key => {
          metadataKeys.push(name + " : " + key)
        })
      })
      this.metadataKeys = metadataKeys;
      this.loadingMetadataKey = false;
      (this.$refs.refSearchValue as HTMLElement).focus();
    }).catch(error => {

    });

    this.loadingMetadataValues = true;
    api.metadataValues(this.$store.getters.token).then((response) => {
      response.data.forEach(item => {
        this.metadataValues[item.name] = item.values;
      })
      this.loadingMetadataValues = false;
    }).catch(error => {
      this.loadingMetadataValues = false;
    });
  }

  mounted() {
    this.loadingMetadataValues = true;
    this.refreshMetadataKey()
    api.metadataValues(this.$store.getters.token).then((response) => {
      response.data.forEach(item => {
        this.metadataValues[item.name] = item.values;
      })
      this.loadingMetadataValues = false;
    }).catch(error => {
      this.loadingMetadataValues = false;
    });
  }

  public selectChanged() {
    (this.$refs.refSearchValue as HTMLElement).focus()
  }

  public removeSearchItem(index) {
    this.searchList.splice(index, 1);
  }

  public addSearchSelectValue(event) {
    this.searchList.push({key: this.selectedKey, value: this.selectedValue})
    this.selectedValue = '';
  }

  public addSearch(event) {
    this.searchList.push({key: this.selectedKey, value: this.searchValue})
    this.searchValue = '';
  }

  public addSearchDate(event) {
    this.searchList.push({key: this.selectedKey, value: this.dateFrom + ' / ' + this.dateTo})
  }

  public downloadFile(item) {
    item.isDownloading = true;
    const notification = {
      content: 'Downloading ' + item.filename + ' ...',
    }
    commitAddNotification(this.$store, notification);
    api.fileDownload(item._id, this.$store.getters.token).then((response) => {
      let blob = new Blob([response.data],
          {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = item.filename
      link.click()
      commitRemoveNotification(this.$store, notification);
      item.isDownloading = false;
    }).catch(error => {
      item.isDownloading = false;
      commitRemoveNotification(this.$store, notification);
      commitAddNotification(this.$store, {content: 'Can not read metadata, reason: ' + error.message, color: "error"})
    });
  }
}

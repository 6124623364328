








































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { readUserProfile } from "@/store/main/getters";
import { api } from "@/api";
import {
  commitAddNotification,
  commitRemoveNotification,
} from "@/store/main/mutations";

@Component
export default class Dashboard extends Vue {
  @Prop(String) public color: string | undefined;
  @Prop({ default: false }) public multiple!: boolean;
  @Prop({ default: false }) public savingInProgress!: boolean;
  @Prop({ default: 1 }) public stepperStep;
  @Prop({ default: null }) public required;
  @Prop({ default: null }) public optional;
  @Prop({ default: null }) public selectedFile;
  dialog = false;
  @Prop({ default: [] }) public requiredMissing;
  validationError = false;
  validationErrorText = '';
  METADATA_REQUIRED = [
    "Title",
    "Version",
    "Partner",
    "Contact",
    "Location",
    "WP/Task",
    "Type",
    "Date",
    "Ownership",
    "Description",
  ];

 VALID_PARTNERS = [
   'ntnu',
  'simtec',
  'mytil',
  'rwth',
  'bnw',
  'befesa',
  'erimsa',
  'ntua',
  'hydro',
  'itmati',
  'sintef',
  'sbc',
  'ws',
  'frey',
  'wacker',
  'siqa1',
  'siqal',
  'dow',
  'hzdr',
  'mintek',
  'mntk',
  'silicor',
  'elkem'
 ]


  @Emit()
  public metadata(e) {
    this.requiredMissing = [];
    const notification = {
      content: "Checking file metadata...",
      showProgress: true,
    };
    commitAddNotification(this.$store, notification);
    this.selectedFile = e.target.files[0];
    api
      .fileMetadata(e.target.files[0], this.$store.getters.token)
      .then((ret) => {
        this.required = ret.data.metadata.required;
        this.optional = ret.data.metadata.optional;
        let requiredKeys : string[] = [];
        this.validationErrorText = "";
        this.required.forEach((element) => {
          if (element.key == "Partner") {
            element.value.split(',').forEach((val) => {
               if (!this.VALID_PARTNERS.includes(val.trim().toLowerCase())) {
                 this.validationErrorText = val.trim() + " is not valid Partner name.";
               }
            });
            
          }
          requiredKeys.push(element.key);
        });
        this.METADATA_REQUIRED.forEach((key) => {
          if (!requiredKeys.includes(key)) {
            this.requiredMissing.push(key);
          }
        });
        if (this.requiredMissing.length > 0) {
          this.dialog = true;
          this.stepperStep = 1;
        } else if (this.validationErrorText) {
          this.validationError = true;
          this.stepperStep = 1;
        } else {
          this.stepperStep = 2;
        }
        commitRemoveNotification(this.$store, notification);
        (this.$refs.fileInput as HTMLInputElement).value = "";
        // this.$refs.fileInput.HTMLElement.value = '';
      })
      .catch((error) => {
        const msg = error.response.data.detail
          ? error.response.data.detail
          : error.message;
        commitRemoveNotification(this.$store, notification);
        commitAddNotification(this.$store, { content: msg, color: "error" });
        (this.$refs.fileInput as HTMLInputElement).value = "";
      });
  }

  public saveFile(e) {
    this.savingInProgress = true;
    api
      .fileSave(this.selectedFile, this.$store.getters.token)
      .then((ret) => {
        commitAddNotification(this.$store, {
          content: "File was saved to database.",
        });
        this.savingInProgress = false;
        this.stepperStep = 1;
      })
      .catch((error) => {
        const msg = error.response.data.detail
          ? error.response.data.detail
          : error.message;
        commitAddNotification(this.$store, { content: msg, color: "error" });
        this.savingInProgress = false;
      });
  }

  get greetedUser() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      if (userProfile.full_name) {
        return userProfile.full_name;
      } else {
        return userProfile.email;
      }
    }
  }

  public trigger() {
    (this.$refs.fileInput as HTMLElement).click();
  }
}
